<script setup>
import { computed, ref } from 'vue'
import { ascending } from 'd3'

// eslint-disable-next-line
const props = defineProps({
  teams: Array,
  active: Number
})

// eslint-disable-next-line
const emit = defineEmits(['changeTeam'])

const open = ref(false)

const active = computed(() => props.teams.find(t => t.lastPosition === props.active))

const others = computed(() => {
  return Array.from(props.teams)
    .filter(t => t.lastPosition !== props.active)
    .sort((a, b) => ascending(a.lastPosition, b.lastPosition))
})

function changeTeam (id) {
  emit('changeTeam', id)
  open.value = false
}
</script>

<template>
  <header class="module">
    <ol>
      <li class="active" @click="open = !open">
        <span>{{ active.name }}</span>
        <svg class="arrow" viewBox="0 0 120 86">
          <path d="M 0 0 h 120 L 60 86 L 0 0" />
        </svg>
      </li>
      <template v-if="open">
        <li
          v-for="team in others"
          :key="team.id"
          @click="changeTeam(team.lastPosition)">
            {{ team.name }}
        </li>
      </template>
    </ol>
  </header>
</template>

<style lang="scss" scoped>
ol {
  list-style: none;
  margin: 0;
  padding: 1ch 0;
  text-align: center;
  text-transform: uppercase;
}

li {
  color: #999;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: bold;
  padding: .25ch 0;
}

.active {
  align-items: center;
  color: black;
  display: flex;
  justify-content: center;
}

.arrow {
  display: inline-block;
  height: .6ch;
  margin-left: .5ch;
}
</style>
