<script setup>
import { computed } from 'vue'
import { sum } from 'd3'

// eslint-disable-next-line
const props = defineProps({
  team: Object,
  filter: String,
  title: String
})

const contracts = computed(() => props.team[props.filter])
const positions = computed(() => {
  return ['QB', 'RB', 'WR', 'TE']
    .map(p => {
      const posContracts = contracts.value.filter(c => c.position === p)
      posContracts.position = p

      return posContracts
    })
    .filter(p => p.length > 0)
})
const totalCost = computed(() => sum(contracts.value, d => d.cost))
const totalDuration = computed(() => sum(contracts.value, d => d.remainingDuration))

</script>

<template>
  <section class="module">
    <h2>{{ title }}</h2>
    <table>
      <thead>
        <tr>
          <th class="position">Pos</th>
          <th class="player">Player</th>
          <th class="cost">$</th>
          <th class="duration">yr</th>
        </tr>
      </thead>
      <tbody v-for="position in positions" :key="position.position" class="group">
        <tr
          v-for="contract in position"
          :key="contract.id">
            <td class="position">{{ contract.position }}</td>
            <td class="player">
              {{ contract.player }}
              <span v-if="contract.tag" class="tag">🔒</span>
            </td>
            <td class="cost">{{ contract.cost }}</td>
            <td class="duration">{{ contract.remainingDuration }}</td>
          </tr>
      </tbody>
      <tfoot>
        <tr>
          <td></td>
          <td class="player">Total:</td>
          <td class="cost">{{ totalCost }}</td>
          <td class="duration">{{ props.filter === 'roster' ? totalDuration : '' }}</td>
        </tr>
        <tr v-if="props.filter === 'dropped'">
          <td></td>
          <td class="player">Owed:</td>
          <td class="cost">{{ Math.ceil(totalCost * 0.5) }}</td>
        </tr>
      </tfoot>
    </table>
  </section>
</template>

<style lang="scss" scoped>
h2 {
  font-size: 0.95em;
  margin: 1.5ch 0;
  text-align: center;
  text-transform: uppercase;
}

table {
  border-collapse: collapse;
  font-size: 0.9em;
  font-variant-numeric: tabular-nums;
  width: 100%;
}

thead, tfoot {
  font-weight: bold;
}

th {
  border-bottom: 1px solid black;
}

td {
  border-bottom: 1px solid transparent;
  padding: .5ch 0;
}

.position {
  color: transparent;
  text-align: center;
  width: 5ch;
}

.player {
  text-align: left;
}

.tag {
  font-size: 0.8em;
  vertical-align: text-top;
}

.cost {
  text-align: right;
  width: 5ch;
}

.duration {
  padding-right: 1ch;
  text-align: right;
  width: 5ch;
}

.group :first-child .position {
  color: inherit;
}

.group :last-child td {
  border-bottom: 1px solid #bbb;
}

tfoot .player {
  text-align: right;
}
</style>
