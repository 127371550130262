<script setup>
import TeamNavigation from '@/components/TeamNavigation.vue'
import TrophyCabinet from '@/components/TrophyCabinet.vue'
import SalaryCap from '@/components/SalaryCap.vue'
import ContractsTable from '@/components/ContractsTable.vue'

import { computed, ref, reactive, onMounted } from 'vue'
import { loadData } from '@/utilities/loadData.js'

import teams from '@/data/teams.yml'

const leagueData = reactive({
  contracts: [],
  capTrades: []
})
const displayTeam = ref(1)

const team = computed(() => {
  const info = teams.find(t => t.lastPosition === displayTeam.value)
  const contracts = leagueData.contracts.filter(c => c.teamId === info.id)
  const roster = contracts.filter(c => !c.isTerminated)
  const dropped = contracts.filter(c => c.isTerminated)

  return {
    info,
    contracts,
    roster,
    dropped
  }
})

onMounted(async () => {
  const data = await loadData()

  leagueData.contracts = data.contracts
  leagueData.capTrades = data.capTrades
})
</script>

<template>
  <main>
    <TeamNavigation class="team-navigation" :teams="teams" :active="displayTeam" @change-team="(id) => displayTeam = id" />

    <TrophyCabinet class="trophy-cabinet" v-if="team.info.trophies !== null" :team="team" />
    <SalaryCap class="salary-cap" :team="team" :trades="leagueData.capTrades" />
    <ContractsTable class="roster" :team="team" title="Current Roster" filter="roster" />
    <ContractsTable class="dropped" :team="team" title="Outstanding Contracts" filter="dropped" />
  </main>
</template>

<style lang="scss">
html {
  font-family: 'Inter'
}

body {
  background-color: #f1f1f1;
}

main {
  display: grid;
  grid-gap: 1ch;
  margin: 0 auto;
  max-width: 1000px;
  padding: 0;
  width: 100%;

  @media (min-width: 800px) {
    grid-template-columns: 1fr 1fr;
    padding: 1ch;
  }
}

.module {
  background-color: white;
  border-radius: .25ch;
  box-shadow: 0px 1px 2px #d3d3d3;
  padding: 1ch;
}

@media (min-width: 800px) {
  .team-navigation { grid-column: 1 / 3; }
  .trophy-cabinet { grid-column: 1 / 3; }
  .salary-cap { grid-column: 1 / 3; }
  .roster { grid-column: 1; }
  .dropped { grid-column: 2; }
}
</style>
