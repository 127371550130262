import { zip, ascending, descending, autoType } from 'd3'

export const loadData = async () => {
  const apiUrl =
    'https://sheets.googleapis.com/v4/spreadsheets/1ZwUftD93TZ2yl_yOhNbhX6-qUY7JDoucQytHHb0VbuE/values:batchGet?key=AIzaSyDh4IK9z_ah4Wzol6jkVevdyy0tr-dEq3k&ranges=Contracts!A1%3AM&ranges=Cap%20Trades!A1%3AH'

  const rawData = await fetch(apiUrl, { method: 'GET' })

  const data = await rawData.json()

  const getRange = async (i) => {
    let [columns, ...values] = await data.valueRanges[i].values
    values = values.map((v) => Object.fromEntries(zip(columns, v)))

    return values
  }

  let contracts = await getRange(0)

  contracts.forEach((c) => {
    c.cost = +c.cost
    c.duration = +c.duration
    c.initialSeason = +c.initialSeason
    c.isTerminated = c.isTerminated === 'TRUE'
    c.tag = +c.tag
    c.teamId = +c.teamId
    c.isActive = c.isActive === 'TRUE'
    c.remainingDuration = +c.remainingDuration
  })

  const positions = ['QB', 'RB', 'WR', 'TE']
  contracts = contracts.sort((a, b) => {
    return (
      positions.indexOf(a.position) - positions.indexOf(b.position) ||
      descending(a.cost, b.cost) ||
      descending(a.remainingDuration, b.remainingDuration) ||
      ascending(a.player, b.player)
    )
  })

  contracts = contracts.filter(
    (c) => c.remainingDuration > 0 || c.duration === 0
  )

  let capTrades = await getRange(1)

  capTrades = capTrades.map(autoType).filter((t) => t.active === 'TRUE')

  return { contracts, capTrades }
}
