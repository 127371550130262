<script setup>
import { computed } from 'vue'
import { sum } from 'd3'

// eslint-disable-next-line
const props = defineProps({
  team: Object,
  trades: Array
})

const tradeOffset = computed(() => {
  const teamId = props.team.info.id

  const credit = props.trades.filter(t => t.toTeamId === teamId)
  const debit = props.trades.filter(t => t.fromTeamId === teamId)

  return sum(credit, d => d.cap) - sum(debit, d => d.cap)
})

const salaryCap = computed(() => 200 + tradeOffset.value)

const rosterHit = computed(() => sum(props.team.roster, d => d.cost))
const droppedHit = computed(() => sum(props.team.dropped, d => d.cost))
const capHit = computed(() => rosterHit.value + Math.ceil(droppedHit.value * 0.5))

const capSpace = computed(() => salaryCap.value - capHit.value)
</script>

<template>
  <section class="module">
    <ul>
      <li>
        <span class="value">$ {{ salaryCap }}</span>
        <span class="label">Salary Cap</span>
      </li>
      <li>
        <span class="value">$ {{ capHit }}</span>
        <span class="label">Cap Hit</span>
      </li>
      <li>
        <span class="value">$ {{ capSpace }}</span>
        <span class="label">Cap Space</span>
      </li>
    </ul>
  </section>
</template>

<style lang="scss" scoped>
ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  flex: 1 0 0;
  padding: 1ch 0;
  text-align: center;

  &:not(:last-child) {
    border-right: 1px solid #ddd;
  }
}

span {
  display: block;
}

.value {
  font-weight: bold;
}

.label {
  font-size: 0.75em;
  text-transform: uppercase;
}
</style>
